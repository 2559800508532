<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg" style="width:100%">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">A must-have E-ticket package for sightseeing in Wakayama. Select popular facilities and restaurants in Wakayama area. You can choose 3  of your favorite facilities within a week. Present the QR code on the E-ticket to easily enter the venue.</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">How to use</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ● The same facility can only be used once, and the same facility can not be re-entered.<br>
          ● Ticket Validity Period: <span>90 Days</span> After Purchase.<br>
          EX: Tickets Purchased On <span>7/1</span> ⇒ <span>Ticket Validity Period:7/1~9/28.</span><br>
          ● Ticket Validity Period: After Scanning The Code At The First Facility, It Is Valid For <span>7 Days.</span><br>
          EX: If You Start To Use It On 7/1 ⇒ Validity Period:<span> 7/1~7/7.</span><br>
          ● Available Facilities: You Can Use <span>3</span>  Facilities During The Valid Period.<br>
          ● Please Note That When You Purchase More Than One Ticket In A Reservation, The Starting Date Will Apply To All Tickets. <br>
          (<span>The Validity Period Is 7 Days</span>).<br>
          ● When The Ticket Has Been Used, The <span>7 Day</span> Validity Period Will Not Exceed The <span>90 Day</span> Validity Period Of The Ticket Itself.<br>
          EX: Ticket Period: <span>7/1~9/28</span>, If The Ticket Starts On <span>9/27</span>, The Valid Time Is: <span>9/27~9/28</span>.<br>
          ● Facilities That Are Unused Cannot Be Refunded.<br>
          ● Please Check The Information About Each Facility, Business Hours, And Public Holidays In Advance At The Following Links.
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='Notice'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='Redemption Location' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='Official Website' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- notice特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfwakayama/en/havefun_title_en.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfwakayama/01_en.png'),
          title: ['NO PHONE NO LIFE TOUR~ Secret trip of Wakayama sightseeing~'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'Wakachika Plaza (Tourist office), 5-12-2, Misonocho, Wakayama City, Wakayama Prefecture'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.wakayamakanko.com/wakayama_asobi/npnl/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Included: Smartphone pouch, transparent folder, white map, special backpack, souvenir of travel'] },
                { cid: 2, text: ['Operation hours: 9:00 - 17:00 *No reservation required'] },
                { cid: 3, text: ['Please check the official website in advance to confirm opening hours.<br>※Closed: December 29 to January 3'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfwakayama/02_en.png'),
          title: ['Wakayama ramen & Gyoza or vegetarian ramen'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '5-7-12 Misonocho, Wakayama City, Wakayama Prefecture'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.instagram.com/menyahishio_jrwakayamaekimae/?igshid=NTc4MTIwNjQ2YQ%3D%3D'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check with the store before visiting as the operation hours and closed days are subject to change.<br>※Closed days: December 29 to January 3'] },
                { cid: 2, text: ['The vegetarian ramen does not come with free Gyoza.'] },
                { cid: 3, text: ['We apologize for the possibility of being unable to sit at the restaurant due to capacity.'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfwakayama/03_en.png'),
          title: ['Traditional Kishu Lacquerware Industry Center - "Uruwashi Section" 1,000-yen discount coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒642-0001, 222 Funoo, Kainan City, Wakayama Prefecture'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.kishusikki.com/en/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please pay any extra fee in cash.'] },
                { cid: 2, text: ['Please check the official website in advance to confirm the opening hours.'] },
                { cid: 3, text: ['If you would like to experience lacquerware Maki-e (sprinkled picture), please apply for it by phone in advance.'] },
                { cid: 4, text: ["he fee of the lacquerware Maki-e experience varies by the lacquerware's size."] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfwakayama/04_en.png'),
          title: ['Kimiidera Temple Special Visit Ticket'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒641-0012, Kimiidera 1201, Wakayama City, Wakayama Prefecture'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.kimiidera.com/worship/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Included: worship fee, entrance fee to the view corridor of Buddha Hall, worship fee to Hall of Great Light, and round-trip cable car fee.'] },
                { cid: 2, text: ['Fees related to prayers, Pariṇamana and worship as well as the worship hours may vary. Please check the website for more details.'] },
                { cid: 3, text: ['There may be days when Hall of Great Light is closed.'] },
                { cid: 4, text: ["Please check the website in advance for the latest opening hours."] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfwakayama/05_en.png'),
          title: ['Hainan City Souvenir and Tourism Center "KAIBUTSU-KUN" - 1,000-yen discount coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '51-2 Nadaka, Kainan City, Wakayama-ken (inside JR Kainan Station)'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.kainankanko.com/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['If there is a price difference, one has to pay the difference on-site.'] },
                { cid: 2, text: ['Please check the official website beforehand to confirm the business hours.'] },
                { cid: 3, text: ['Please present your e-ticket at the checkout counter without exchanging it for a paper ticket.'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfwakayama/06_en.png'),
          title: ['Hotel Granvia Wakayama Lobby Café PASSWORD "Cake Set"'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '5-18, Tomodacho, Wakayama City, Wakayama Prefecture'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.granvia-wakayama.co.jp/english/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Included: cake and drink.'] },
                { cid: 2, text: ['If there is a price difference, one has to pay the difference on-site. '] },
                { cid: 3, text: ["Please check the website in advance for operation hours.<br>※The restaurant's seats may not be available due to temporary closure or full capacity."] },
                { cid: 4, text: ["The attached product images are for reference only."] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfwakayama/07_en.png'),
          title: ['Bicycle Rental・E-bike 1,000-yen (One-day) Coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '〒649-5335, 6-1-4 Tsukiji, Nachikatsuura-cho, Higashimuro-gun, Wakayama Prefecture'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://nachikan.jp/en/activity/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['The 1,000-yen discount is available for a user.'] },
                { cid: 2, text: ['Fees may vary depending on the time of arrival and the type of bicycle.'] },
                { cid: 3, text: ['Reservations are not required. Since the number of vehicles is limited, if you hope to make sure the vehicle is available, please contact "travel@nachikan.jp" by mail in advance.'] },
                { cid: 4, text: ["Please confirm the opening hours on the website in advance."] },
                { cid: 5, text: ["If there is a price difference, please pay the difference on-site."] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfwakayama/08_en.png'),
          title: ['Japenese Heian Clothing Wearing Experience 1,000-yen Coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                'Nachikatsuura Tourist Office, 6-1-4 Tsukiji, Nachikatsuura-cho, Higashimou-gun, Wakayama Prefecture ','Daimonzaka Tourist Information Center, Nono 3034-2, Nachikatsuura-cho, Higashimulo-gun, Wakayama Prefecture'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://nachikan.jp/en/activity/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['The 1,000-yen discount is available for a user.'] },
                { cid: 2, text: ['If there is a price difference, please pay the difference on-site (cash only).'] },
                { cid: 3, text: ['There are no coin lockers at Daimonzaka Tea House. Only numbered big black bags are available for storage of clothing on-site. Please note that it may not be possible to store valuable items.'] },
                { cid: 4, text: ["Please check the website in advance for hours of operation."] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfwakayama/09_en.png'),
          title: ['"Senryu" Coupon (options to choose from)'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '4-3-27 Tsukiji, Nachikatsuura-cho, Wakayama Prefecture'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://nachikan.jp/en/restaurant/4833/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Choose one of the following: "Pacific Saury sushi (with soup) or pork cutlet Don (with soup)", or add 500 yen for additional famous Tuna Don (with soup).'] },
                { cid: 2, text: ['Please check the website in advance for operation hours.'] },
                { cid: 3, text: ["Please note that one may not be able to use the restaurant's seats due to capacity."] },
                ]
            }
          ]
        },
        {
          id: 10,
          img: require('@/assets/images/hfwakayama/10_en.png'),
          title: ['"Nigiwai-Ichiba" Special Lunch Coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                '7-12 Tsukiji, Nachikatsuura-cho, Higashimuro-gun, Wakayama Prefecture (Store name: Maguro No Wakiguchi)'],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://nigiwaiichiba.com/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website in advance to confirm the opening hours.<br>※Closed: Tuesdays'] },
                { cid: 2, text: ['Last order: before 15:30.'] },
                { cid: 3, text: ["We apologize for the possibility of being unable to use the restaurant seats due to capacity."] },
                ]
            }
          ]
        },
        {
          id: 11,
          img: require('@/assets/images/hfwakayama/11_en.png'),
          title: ['Ki-no-Matsushima Islands Sightseeing Ship'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                "442-20 Nachikatsuura-cho, Higashimuro-gun, Wakayama Prefecture (Ki-no-Matsushima Islands' reception counter)"],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['http://kinomatsushima.com/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the operational status and schedule on the website in advance.<br>※Closed: Wednesdays (However, it may be temporarily closed during storms or low season.)'] },
                { cid: 2, text: ['We are sorry that there may be cases where the facility is not available due to full occupancy.'] },
                ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfwakayama/12_en.png'),
          title: ['Animal Play Zone Adventure World - 1-day admission ticket 1000-yen coupon'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                "〒649-2201, 2399 Katada, Shirahama-cho, Nishimuro-gun, Wakayama Prefecture (Ticketing Window)"],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.aws-s.com/en/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['You can enjoy a 1,000-yen price deduction (admission ticket only).'] },
                { cid: 2, text: ["Please check the official website for the facilities' business hours in advance."] },
                { cid: 3, text: ["If there is a price difference, the difference needs to be paid on-site."] },
                ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfwakayama/13_en.png'),
          title: ['SHIRAHAMA KEY TERRACE Hotel Seamore - "One-day hot spring bath coupon"'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                "〒649-2211, (Counter) 1821, Shirahama-cho, Nishimuro-gun, Wakayama Prefecture "],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.keyterrace.co.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the website in advance for operation hours.'] },
                { cid: 2, text: ["Please note that the facility may not be able to operate due to capacity."] },
                ]
            }
          ]
        },
        {
          id: 14,
          img: require('@/assets/images/hfwakayama/14_en.png'),
          title: ['Shirahama Coral Princess Tower Admission Ticket'],
          message: [
            {
              msgId: 1,
              title: 'Redemption Location',
              content: [{ cid: 1, text: [
                "〒649-2211, 1821 Shirahama-cho, Nishimuro-gun, Wakayama Prefecture (Ticketing office)"],noColor:true }]
            },
            {
              msgId: 2,
              title: 'Official Website',
              content: [
                { cid: 1, text: ['https://www.keyterrace.co.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: 'Notice',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['Please check the official website in advance to confirm the opening hours.<br>※There may be temporary closures due to bad weather.'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFWakayama&l=en')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-wakayama-1-week-free-pass/en', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>